// swiperjs

new Swiper('.section__reviews__carousel .swiper', {
    slidesPerView: 'auto',
    autoplay: {
    delay: 1500,
    disableOnInteraction: true,
    },
    freeMode: true,
    spaceBetween: 40,
    loop: true,
    speed: 1500,
});

(function () {
  var throttle = function (type, name, obj) {
    var obj = obj || window;
    var running = false;
    var func = function () {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function () {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  throttle("scroll", "optimizedScroll");
})();